import React, { Fragment, useMemo } from "react";
import { fetchAvailableDrivers } from "../../api/PreplanApi";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import _ from "lodash";
import moment from "moment"
import { Modal, Button, List } from 'antd';
import {
  Col,
  Row,
  Tag,
  Typography,
  Link,
  Popover
} from "../../common/UIComponents";
import { checkAccessExistance, checkServiceExistance } from "../../helpers/common";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import TypeOfOrder from "../orders/TypeOfOrder";
import { isEmpty } from "../../common/Common";
import Copyable from "../common/Copyable";
import AppConfig from "../../config/AppConfig";
import OrderAppointments from "../orders/OrderAppointments";
import { getAppointmentTime } from "../../helpers/orders";
import AddressInfo from "../common/AddressInfo";
const { Text } = Typography;

const { orderTypeColorMapping } = AppConfig

export const getAvailableDriversInfo = (startDate, endDate, startTime, endTime, zone_ids="", warehouse_id=""
) => {
  const startDeliveryTime = moment(`${moment(startDate).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm').format();
  const engDeliveryTime = moment(`${moment(endDate).format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm').format();
  return new Promise((resolve , reject) => {
    fetchAvailableDrivers(startDeliveryTime, engDeliveryTime, zone_ids, warehouse_id)
      .then((result) => {
        if (result.success) {
          const drivers = result.users || [];
          resolve({
            drivers: _.sortBy(drivers, 'employee_code'),
          });
        } else {
          reject();
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      });
  }
  );
};

// This function takes in an array of driver allocation errors and builds a description string from them.
// It returns a string with descriptions separated by new lines

export const renderDriverAllocationErrors = (allErrors) => {
  const assignedToOtherRoute = allErrors.find((error) => error.type === "assigned_to_other_route");
  const invalidAppointment = allErrors.find((error) => error.type === "invalid_appointment");
  const invalidLocation = allErrors.find((error) => error.type === "invalid_location");

  const buildDescription = (error) => {
    switch (error.type) {
      case "assigned_to_other_route":
        return error.orders.map((order) => {
          const [ orderNumber, routeNumber, date ] = order.split("-");
          return `The order ${orderNumber} is assigned to route ${routeNumber} on ${moment(date, "MM/DD/YY").format("Do MMMM YYYY")} \n`; 
        })
      case "invalid_appointment":
        return `The orders ${error.orders.join(", ")} having invalid appointments`;
      case "invalid_location":
        return `The orders ${error.orders.join(", ")} having invalid address`;
      default:
        return "";
    }
  };

  let description = '';
  if (assignedToOtherRoute && assignedToOtherRoute.orders.length > 0) {
    description += buildDescription(assignedToOtherRoute);
  }
  if (invalidAppointment && invalidAppointment.orders.length > 0) {
    if(description.length){
      // add a new line if there is already a description
      description += " /n ";
    }
    description += buildDescription(invalidAppointment);
  }
  if (invalidLocation && invalidLocation.orders.length > 0) {
    if(description.length){
      // add a new line if there is already a description
      description += " /n ";
    }
    description += buildDescription(invalidLocation);
  }

  return description;
};

// export const renderOrderNumType = (data, record, showTriggerEdit, handleEditOrder, refreshCallback, showdetailslink , isInAggrid = false, showDetailIcon = false, showEditIcon = false) => {

export const renderOrderNumType = (data, record, showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink =false, isInAggrid = false, gotoOrderDetails, showEditIcon = false) => {
  const textColor = showTriggerEdit && record.status === "EXCEPTION" ? "textRed" : "";

  const renderTypeOfOrderTag = () => (
    record?.type_of_order && (
      <div style={{ textAlign: 'center' }} className="type-of-order-tag-container">
        <TypeOfOrder
          order={record}
          orderTypeKey="type_of_order"
          relatedOrderKey="related_order"
          placement="topLeft"
          showBadge={true}
          isInAggrid={true}
        />
      </div>
    )
  );

  const renderOrderLink = () => (
    <Link
      onClick={ () => handleEditOrder(record.id) }
      className={ textColor }
    >
      { record.status === "EXCEPTION" ? (
        <Popover
          title="Exception message"
          content={ !isEmpty(record.exception_message) ? record.exception_message : "Reason Not Available" }
          overlayClassName="popoverWidth"
        >
          { data }
        </Popover>
      ) : data }
    </Link>
  );

  const defaultRender = () => (
    <Row type="flex" align="middle" justify="space-around">
      <Col><Text className={ textColor }>{ data }</Text></Col>
      <Col>{ renderTypeOfOrderTag() }</Col>
    </Row>
  );

  if (!checkServiceExistance("COS")) {
    return defaultRender();
  }

  if (showTriggerEdit) {
    return (
      <Row type="flex" justify="space-between" align="middle" className={ isInAggrid ? "type-of-order-tag-container" : "" }>
        <Col>{ renderTypeOfOrderTag() }</Col>
        <Col>{ renderOrderLink() }</Col>
        <Col><Copyable text={ data } /></Col>
      </Row>
    );
  }

  if (showDetailsLink !== false) {
    return (
      <Row type="flex" align="middle" gutter={ 8 }>
        <Col>
          <div style={{ textAlign: 'center' }} className="type-of-order-tag-container">
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
              isInAggrid={true}
            />
          </div>
        </Col>
        <Col>
         {
          gotoOrderDetails ?
          <Link onClick={ () => { gotoOrderDetails(record.id); } }>{ data }</Link>
          :
          <CustomerOrderDetails
              order_no={ data }
              order={ {
                id: record.id,
                customer_order_number: data,
              } }
              showEdit={ showEditIcon && ![ 'PENDING', 'REJECTED' ].includes(record.status) ? true : false }
              editClick={ () => handleEditOrder(record.id) }
              refreshCallback={ refreshCallback }
            />
         }
        </Col>
        {  gotoOrderDetails  && <Col>
          <Copyable text={ data } />
        </Col> }
      </Row>
    );
  }; 

  return defaultRender();
};



export function defaultSearchOrdersfilter(props) {
  return {
    fromDate: props.startTime ? props.startTime : moment(),
    toDate: props.endTime ? props.endTime : moment().add(2, "day"),
    filter: "",
    search_order_token: "",
    sortBy: "none",
    sortByType: "descend",
    account_codes: [],
    warehouse_id: props.showRouteAssigner ? props.warehouse_id : !_.isEmpty(props.route.warehouse_id) ? props.route.warehouse_id : '',
    search_key_type: "",
    search_key_value: "",
    [I18n.t("order.filters.schedule_orders.type")]: I18n.t(
      "order.filters.schedule_orders.scheduled_key"
    ),
    zone_ids: [],
    // created_by : "",
    // search_type: 'EQUALS'
  }
}

export function defaultSearchRecoveryfilter(props) {
  return {
    freight_forwarder: '',
    dateSearchBy: 'recovery_eta',
    dateSearchValue:  { 
      fromDate: props.startTime ? moment(props.startTime) : moment(), 
      toDate: props.endTime ? moment(props.endTime) : moment().add(6, "day") 
    },
    account_code: '',
    sortBy: "none",
    sortByType: "descend",
    [I18n.t('recoveries.schedule_times.type')]: props.scheduled_type
      ? props.scheduled_type
      : I18n.t("recoveries.schedule_times.both_key"),
    zone_ids: [],
  }
}

export function defaultSearchReleaseFilter(props, defaultStatus) {
  return {
    // warehouse_id: "ALL",
    account_code: "",
    sortBy: "none",
    sortByType: "descend",
    zone_ids: [],
    currentStatus: defaultStatus,
    dateSearchBy: "scheduled_release_date",
    dateSearchValue: { 
      fromDate: props.startTime ? moment(props.startTime) : moment(), 
      toDate: props.endTime ? moment(props.endTime) : moment().add(6, "day") 
    },
    [I18n.t("recoveries.schedule_times.type")]: props.scheduled_type ? props.scheduled_type : I18n.t("recoveries.schedule_times.both_key"),
    release_type: props?.reqFrom === 'dispatch' ? I18n.t('releases.release_type.options.OTHER_KEY') : I18n.t('releases.release_type.options.BOTH_KEY') 
  }
}

export function defaultSearchLHFilter(props, selectedWarehouses, destinationPoint) {
  return (
  {
    dateSearchValue: { 
      fromDate: props.startTime ? moment(props.startTime) : moment(), 
      toDate: props.endTime ? moment(props.endTime) : moment().add(6, "day") 
    },
    account_code: '',
    sortBy: "none",
    sortByType: "descend",
    startingPoint: Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses,
    destinationPoint,
    warehouse_id: Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses,
  })
}

export function defaultARChequefilter(props) {
  return {
    dateSearchBy: 'payment_date',
    dateSearchValue:  { 
      fromDate: null, 
      toDate: null 
    },
    batch_no: '',
    check_ach_no: ''
  }
}

export function defaultTransactionfilter(props) {
  return {
    search_by_type: 'INVOICE',
    search_value: '',
    sortBy: "none",
    sortByType: "ascend",
  }
}

export function defaultBatchHomeScreenFilter(props) {
  return {
    dateSearchValue:  {
      formattedFrom_date: moment().subtract(6, "day"),
      formattedTo_date:  moment(),
      sortBy: "none",
      sortByType: "ascend",
  }
}
}

export function defaultManageBatchFilter(props) {
  return {
    from_date: moment().subtract(29, "day"),
    to_date: moment(),
    account_code: "",
    status: "BOTH",
    sortBy: "none",
    sortByType: "descend",
  };
}

export function defaultCreditNotesFilter(props) {
  return {
    fromDate: moment().subtract(29, "day"),
    toDate: moment(),
    account_id:"",
    status: "BOTH",
    category_type: "ALL",
    sortBy: "none",
    sortByType: "descend",
  }
}


export const showRouteAssignmentModal = ({
  orderNumbers = [],
  route = {},
  navigateToRoute = () => {},
  closeModal = () => {},
}) => {
  // Helper to format the driver's name or use employee code if present
  const getDriverInfo = (driver) => {
    return !isEmpty(driver.employee_code)
      ? driver.employee_code
      : formatFullName(driver);
  };

  // Helper to handle navigation and cleanup
  const handleNavigate = () => {
    navigateToRoute(route);
    closeModal(); // Assuming closeModal is a function that calls Modal.destroyAll()
  };

  Modal.info({
    title: 'Route Assignment Confirmation',
    content: (
      <div>
        { route?.primary_driver && (
        <>
        <p>
          The following orders have been successfully assigned to:
        </p>
          <p>
            <strong>Route #: {getDriverInfo(route?.primary_driver)}</strong>
          </p>
        </>
        )}
        <p>
          the following orders are assigned:
        </p>
        <OrderNumberList data={ orderNumbers } maxDisplay={ 5 } />
        { checkAccessExistance('DISPATCH') && <div style={ { marginTop: '16px' } }>
          <Row type="flex" gutter={8}>
            <Col>
          <Button type="primary" onClick={ handleNavigate } icon="eye">
            View Assigned Route
          </Button>
              </Col>
            <Col>
          <Button onClick={ closeModal } type="danger">
            Close
          </Button>
            </Col>
          </Row>
        </div>
        }
      </div>
    ),
    onOk () {
      closeModal();
    },
    okText: 'Close',
    width: 520,
    okButtonProps: { style: { display: 'none' } }, // Hide default OK button if not needed
    onCancel () {
      closeModal();
    }
  });
};

export const OrderNumberList = ({ data, maxDisplay = 2 }) => {
  const displayedOrders = data.slice(0, maxDisplay).map(orderNumber => (
    <Col key={ orderNumber } style={ { marginRight: 8 } }>
      { orderNumber }
      {/* Assuming Copyable is a component that you have defined elsewhere */ }
      <Copyable text={ orderNumber } />
    </Col>
  ));

  const remainingOrders = data.length > maxDisplay ? (
    <Col>
      <strong>... { data.length - maxDisplay } more</strong>
    </Col>
  ) : null;

  const content = (
    <div>
      { data.map(orderNumber => (
        <div key={ orderNumber }>
          { orderNumber }
          <Copyable text={ orderNumber } />
        </div>
      )) }
    </div>
  );

  if(data.length > maxDisplay) {
  return (
    <Popover content={ content } title="Customer Orders" overlayStyle={ { width: 200 } }>
      <Row type="flex" justify="start" align="middle">
        { displayedOrders }
        { remainingOrders }
      </Row>
    </Popover>
  );
  }
  return (
    <Row type="flex" justify="start" align="middle">
      { displayedOrders }
    </Row>
  )
};

export const AppointmentDisplay = (props) => {
  const { data , hideAppointmentdate = false , organizationSettings, showPDLabels, isMultiStop = false } = props;
  const allAppointments = _.get(data, "appointments", []);
  const timezone = _.get(data, "tz_short_form", "");
  const orderType = _.get(data, "type_of_order", "");
  const confirmedAppointments = allAppointments.filter(
    (appointment) => appointment.confirmed
  );

  if (data.appointments.length === 0) {
    return (
      <div className="no-appointments">
        <p>No appointments scheduled for this order.</p>
      </div>
    );
  }

  return allAppointments.length > 0 && (
    <Popover
      size="small"
      content={
        <div className="table-contact-info width500 fontsize12">
          <OrderAppointments
            appointments={ allAppointments }
            timezone={ timezone }
            showPDLabels={showPDLabels}
            isMultiStop={isMultiStop}
          />
        </div>
      }
      title={ I18n.t("appointmentForm.preference_title") }
    >
      <span>
        <u className="appointmentText">
          {isMultiStop ? `${allAppointments.length} Appointments` : getAppointmentTime(allAppointments, organizationSettings, hideAppointmentdate, orderType)}
        </u>
      </span>
    </Popover>
  );
};


const createAddressPopover = (address, titleKey, defaultTitle) => (
  <Popover
    title={address?.city ? I18n.t(titleKey) : I18n.t(defaultTitle)}
    content={<AddressInfo address={address} />}
    overlayStyle={{ width: 200 }}
  >
    {address && address.city
      ? `${address.location_code ? address.location_code : address.city}`
      : "NA"}
  </Popover>
);

export const fetchLocAddress = (data, type) => useMemo(() => {
  const sourcePoint =
    data?.origin && Object.keys(data.origin).length !== 0
      ? data.origin
      : data.warehouse_address;
  const destinationPoint =
    data?.destination && Object.keys(data.destination).length !== 0
      ? data.destination
      : data.customer_address;

  const pickUpAddress = createAddressPopover(sourcePoint, "general.origin_location", "general.warehouse_address");
  const dropAddress = createAddressPopover(destinationPoint, "general.destination_location", "general.customer_address");

  if (type === "origin") {
    if (["R", "TR"].includes(data.type_of_order)) {
      return dropAddress;
    }
    return pickUpAddress;
  }
  if (["R", "TR"].includes(data.type_of_order)) {
    return pickUpAddress;
  }
  return dropAddress;
}, [data]);

export const getAddress = (record, key) => {
  const addressKeys = [
    "address_line1",
    "address_line2",
    "city",
    "state",
    "country",
    "zipcode",
  ];
  if (!["T", "LH"].includes(record.type_of_order)) {
    const type = ["R", "TR"].includes(record.type_of_order)
      ? "P"
      : ["D", "TD"].includes(record.type_of_order)
      ? "D"
      : "";
    let address;
    if (key) {
      address =
        key === "pickup"
          ? _.get(record, "origin", {})
          : _.get(record, "destination", {});
    } else {
      address = _.get(record, "customer_address", {});
    }

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  } else {
    const address =
      key === "pickup"
        ? _.get(record, "origin", {})
        : _.get(record, "destination", {});

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  }
};

export const getAddressForPreplanCol = (record, key) => {
  const addressKeys = [
    "address_line1",
    "address_line2",
    "city",
    "state",
    "country",
    "zipcode",
  ];
  if (!["T", "LH"].includes(record.type_of_order)) {
    const type = ["R", "TR"].includes(record.type_of_order)
      ? "P"
      : ["D", "TD"].includes(record.type_of_order)
      ? "D"
      : "";
    let address;
    if (key) {
      address =
        key === "pickup"
          ? _.get(record, "locations[0].l_address", {})
          : _.get(record, "locations[0].l_address", {});
    } else {
      address = _.get(record, "customer_address", {});
    }

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  } else {
    const address =
      key === "pickup"
        ? _.get(record, "locations[0].l_address", {})
        : _.get(record, "locations[1].l_address", {});

    if (_.isEmpty(address)) {
      return "";
    }

    const filteredValues = addressKeys
      .map((e) => address[e])
      .filter((e) => !_.isEmpty(e));

    return `${filteredValues.join(", ")}`;
  }
};

export const fetchAddress = (data, type) => useMemo(() => {
  const sourcePoint =
    data?.origin && Object.keys(data.origin).length !== 0
      ? _.get(data.origin, "l_address", {}) 
      : data.warehouse_address;
  const destinationPoint =
    data?.destination && Object.keys(data.destination).length !== 0
      ? _.get(data.destination, "l_address", {}) 
      : data.customer_address;

  const pickUpAddress = createAddressPopover(sourcePoint, "general.origin_location", "general.warehouse_address");
  const dropAddress = createAddressPopover(destinationPoint, "general.destination_location", "general.customer_address");

  if (type === "origin") {
    if (["R", "TR"].includes(data.type_of_order)) {
      return dropAddress;
    }
    return pickUpAddress;
  }
  if (["R", "TR"].includes(data.type_of_order)) {
    return pickUpAddress;
  }
  return dropAddress;
}, [data]);

// export const ZonesDisplay = (data) => useMemo(() => {
//   const content = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : _.join(data.order_zone_name, ", ");

//   const title = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : `${data.order_zone_name.length > 1
//       ? `${_.first(data.order_zone_name)} (+${data.order_zone_name.length - 1})`
//       : _.first(data.order_zone_name)
//     }`;

//   return (
//     <Popover
//       title="Zones List"
//       placement="topLeft"
//       content={ <div>{ content }</div> }
//       overlayStyle={ { width: 250 } }
//     >
//       { title }
//     </Popover>
//   );
// }, [ data ]);



export const ZonesDisplay = ({ data }) => useMemo(() => {
  
  const content = _.isEmpty(data.order_zone_name)
    ? "NA"
    : typeof data.order_zone_name[0] !== 'object'
      ? _.join(data.order_zone_name, ", ")
      : _.join(data.order_zone_name.map((e) => {
        return e.zone_name
      }),", ");

  const title = _.isEmpty(data.order_zone_name)
    ? "NA"
    : `${data.order_zone_name.length > 1
      ? `${_.isUndefined(_.first(data.order_zone_name).zone_name) ? _.first(data.order_zone_name) : _.first(data.order_zone_name).zone_name } (+${data.order_zone_name.length - 1})`
      : _.isUndefined(_.first(data.order_zone_name).zone_name)
        ? _.first(data.order_zone_name) : _.first(data.order_zone_name).zone_name
    }`;

// export const ZonesDisplay = ({ data }) => useMemo(() => {
//   const content = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : _.join(data.order_zone_name, ", ");

//   const title = _.isEmpty(data.order_zone_name)
//     ? "NA"
//     : `${data.order_zone_name.length > 1
//       ? `${_.first(data.order_zone_name)} (+${data.order_zone_name.length - 1})`
//       : _.first(data.order_zone_name)
//     }`;

  return (
    <Popover
      title="Zones List"
      placement="topLeft"
      content={ <div>{ content }</div> }
      overlayStyle={ { width: 250 } }
    >
      { title }
    </Popover>
  );
}, [ data ]);

export function pinLikeMarker (stopNo = null, bgColor, fgColor, size = 20) {
  // Create a new canvas element
  let hexBg = `#${bgColor}`;
  let hexFg = `#${fgColor}`;
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size * 1.25; // Extra height for the pin, proportional to the size

  // Get the 2D drawing context
  const ctx = canvas.getContext('2d');

  // Draw the round top of the marker
  const radius = size * 0.425; // Radius proportional to the size
  ctx.beginPath();
  ctx.arc(size / 2, radius, radius, 0, 2 * Math.PI, false); // Use 2 * Math.PI to draw a complete circle
  ctx.closePath();
  ctx.fillStyle = hexBg;
  ctx.fill();
  ctx.strokeStyle = '#000000'; // Replace hexBorderColor with the color you desire
  ctx.lineWidth = 1.5; // Replace borderWidth with the desired border width
  ctx.stroke();

  // Draw the pin
  ctx.beginPath();
  ctx.moveTo(size / 2, radius * 2); // Move to the bottom of the circle
  ctx.lineTo(size / 2, size * 1.25); // Draw a line to the desired position
  ctx.strokeStyle = '#000000'; // Set the line color (same as the border color)
  ctx.lineWidth = 1.25; // Set the line width (same as the border width)
  ctx.stroke();

  // ctx.beginPath();
  // ctx.moveTo(size / 2 - size * 0.125, radius); // Position proportional to the size
  // ctx.lineTo(size / 2 + size * 0.125, radius); // Position proportional to the size
  // ctx.lineTo(size / 2, size * 1.25); // Position proportional to the size
  // ctx.closePath();
  // ctx.fillStyle = hexBg;
  // ctx.fill();

  if (typeof stopNo === 'number') {
    // Draw the stop number
    ctx.font = `bold ${size * 0.55}px Arial`; // Font size proportional to the size
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = hexFg;
    ctx.fillText(stopNo, size / 2, size / 2);
  }

  // Return the data URL for the canvas
  return canvas.toDataURL();
}

export const calculateTotalWeight = (orders) => {
  let totalWeight = 0;

  orders.forEach((order) => {
    totalWeight += order.weight;
  });

  return totalWeight;
};

export const calculatePercentage = (weight, totalWeight) => {
  return (weight / totalWeight) * 100;
};